import LinkListItem from "./components/LinkListItem/LinkListItem";
import classes from "./App.module.css";
import Footer from "./components/Footer/Footer";
import { useEffect, useState } from "react";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import Loading from "./components/Loading/Loading";
// import logo from process.env.PUBLIC_URL + '/logo512.png'

function App() {
  const [linkListState, setlinkListState] = useState([]);
  const [loading, setloading] = useState(true);

  function compare(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    setloading(true);
    async function fetchData() {
      const data = await getDocs(collection(db, "linktree"));
      const tmpData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      tmpData.sort(compare);
      setlinkListState(tmpData);
    }
    setloading(false);

    fetchData();
  }, []);

  return (
    <div className={classes.app}>
      <div className={classes.content}>
        <div className={classes.titleDiv}>
          <a href="https://www.visure.ch" target="_blank" rel="noreferrer">
            <img
              src={process.env.PUBLIC_URL + "/logo512.png"}
              alt="VISURE GmbH Logo"
              style={{ width: "150px", maxWidth: "30vw" }}
            />
          </a>
          <h1>VISURE GmbH Links</h1>
        </div>

        <div className={classes.listContainer}>
          <Loading visible={loading} />
          {!loading &&
            linkListState.map((item, index) => {
              if (!item.active) {
                return null;
              }
              return (
                <LinkListItem
                  key={index}
                  text={item.name}
                  link={item.link}
                  important={item.shake}
                />
              );
            })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
