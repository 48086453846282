import classes from "./Footer.module.css";
import {
  FaInstagram,
  FaLinkedin,
  FaRegCopyright,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <a
        href="https://instagram.com/visure.ch"
        rel="noreferrer"
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        href="https://www.youtube.com/channel/UCm2eMUrTnf7RcyXYxSSvaFQ"
        rel="noreferrer"
        target="_blank"
      >
        <FaYoutube />
      </a>
      <a
        href="https://www.linkedin.com/company/visure-gmbh"
        rel="noreferrer"
        target="_blank"
      >
        <FaLinkedin />
      </a>
      <br />
      VISURE GmbH {new Date().getFullYear()} <FaRegCopyright />
    </footer>
  );
};

export default Footer;
