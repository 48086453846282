import classes from './LinkListItem.module.css'

const LinkListItem = (props) => {
    return (
            <a href={props.link} >
                <div className={`${classes.normalButton} ${props.important && classes.shakeButton}`}>{props.text}</div>
            </a>
    )
}

export default LinkListItem