import classes from './Loading.module.css'

const Loading = (props) => {
    return (
      <div
        className={`${classes.ldsEllipsis} ${!props.visible && classes.hide}`}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
}

export default Loading