// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from "@firebase/firestore"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmqWaPRO_b-djbq4KO4p_OUSQcuk63N1M",
  authDomain: "linktree-backend.firebaseapp.com",
  projectId: "linktree-backend",
  storageBucket: "linktree-backend.appspot.com",
  messagingSenderId: "80079813273",
  appId: "1:80079813273:web:fab40a913dc1893f939608",
  measurementId: "G-0CNKG5BFDG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

